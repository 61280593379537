<template>
  <div>
    <section>
      <v-row>
        <v-col cols="12">
          <v-card :disabled="helpers.attendanceTable.loading">
            <v-data-table
              v-model="helpers.attendanceDeletion.selection"
              :headers="headers"
              :loading="helpers.attendanceTable.loading"
              :items="attendancesLocal.data"
              disable-pagination
              item-key="meta.id"
              hide-default-footer
              sort-by="attendanceStatus"
              single-expand
              :expanded.sync="expandedAttendanceRows"
              group-by="groupByDivision"
              show-expand
              show-select
              dense
            >
              <template v-slot:top>
                <v-card flat>
                  <v-card-title class="body-2 v-card__subtitle">
                    <v-spacer class="hidden-sm-and-down"></v-spacer>
                    <v-btn
                      depressed
                      text
                      outlined
                      color="error"
                      class="mr-2"
                      :disabled="!helpers.attendanceDeletion.selection.length"
                      :loading="helpers.attendanceDeletion.loading"
                      @click="deleteAttendanceSelection"
                      ><v-icon left>mdi-delete</v-icon>Auswahl löschen
                    </v-btn>

                    <v-btn
                      depressed
                      text
                      outlined
                      class="mr-2"
                      disabled
                      @click="massEditAttendanceSelection()"
                      ><v-icon left>mdi-pencil-box-multiple</v-icon>Auswahl
                      bearbeiten
                      <!-- :disabled="!helpers.attendanceDeletion.selection.length" -->
                    </v-btn>
                    <v-item-group
                      class="v-btn-toggle v-btn-toggle--dense success"
                      :class="$vuetify.breakpoint.xsAndDown ? 'mt-2' : ''"
                    >
                      <v-menu
                        v-model="helpers.attendanceSelector.model"
                        :close-on-click="false"
                        :close-on-content-click="false"
                        offset-y
                        left
                        :nudge-width="250"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            depressed
                            color="success"
                            v-bind="attrs"
                            v-on="on"
                            ><v-icon left color="white">mdi-plus</v-icon
                            >Hinzufügen</v-btn
                          >
                        </template>
                        <v-card>
                          <!-- @submit.prevent="addUnit" -->
                          <v-form
                            ref="attendanceSelector"
                            v-model="helpers.attendanceSelector.isValid"
                          >
                            <v-list>
                              <v-list-item>
                                <v-select
                                  v-model="helpers.newAttendanceEntry.user"
                                  :items="addableAttendees"
                                  item-text="user.displayName"
                                  item-value="user.uid"
                                  class="pt-1 mb-2"
                                  :rules="[rules.required]"
                                  return-object
                                  label="Person auswählen"
                                  hide-details="auto"
                                  no-data-text="Keine Personen vorhanden"
                                  outlined
                                >
                                </v-select>
                              </v-list-item>
                              <v-list-item>
                                <v-btn-toggle
                                  v-model="
                                    helpers.newAttendanceEntry.attendanceStatus
                                  "
                                  dense
                                  mandatory
                                >
                                  <v-btn value="1" small
                                    ><v-icon left>mdi-check-circle</v-icon
                                    >Anwesend</v-btn
                                  >
                                  <v-btn value="2" small
                                    ><v-icon left>mdi-close-circle</v-icon
                                    >Entschuldigt</v-btn
                                  >
                                  <v-btn value="3" small
                                    ><v-icon left>mdi-close-box</v-icon
                                    >Unentschuldigt</v-btn
                                  >
                                </v-btn-toggle>
                              </v-list-item>
                            </v-list>

                            <v-card-actions class="mt-n4">
                              <v-btn
                                text
                                @click="
                                  helpers.attendanceSelector.model = false
                                "
                              >
                                Abbrechen
                              </v-btn>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="success"
                                text
                                :disabled="!helpers.attendanceSelector.isValid"
                                @click="addAttendanceEntry"
                              >
                                <v-icon left>mdi-plus</v-icon>Hinzufügen
                              </v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-menu>

                      <v-menu offset-y left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            depressed
                            color="success"
                            icon
                            v-on="on"
                            v-bind="attrs"
                            ><v-icon color="white"
                              >mdi-chevron-down</v-icon
                            ></v-btn
                          >
                        </template>
                        <v-list dense nav>
                          <v-list-item
                            v-if="reportLocal.eventId ? true : false"
                            @click="loadResponsesFromEvent()"
                          >
                            <v-list-item-icon>
                              <v-icon
                                >mdi-account-arrow-down</v-icon
                              ></v-list-item-icon
                            >
                            <v-list-item-title class=""
                              >Rückmeldungen aus Veranstaltung
                              übernehmen</v-list-item-title
                            >
                          </v-list-item>
                          <v-list-item disabled>
                            <v-list-item-title
                              ><v-list-item-icon>
                                <v-icon disabled
                                  >mdi-account-plus</v-icon
                                ></v-list-item-icon
                              >Gast hinzufügen</v-list-item-title
                            >
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-item-group>
                  </v-card-title>
                </v-card>
              </template>
              <template v-slot:[`group.header`]="{ items, isOpen, toggle }">
                <th :colspan="headers.length + 1">
                  <v-icon @click="toggle" class="mr-2"
                    >{{ isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
                  </v-icon>
                  {{ items[0].user.division.title }}
                </th>
              </template>

              <template v-slot:item.duration.start.date="{ item }">
                <v-text-field
                  v-if="item.attendanceStatus == '1'"
                  v-model="item.duration.start.date"
                  type="date"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field
              ></template>
              <template v-slot:item.duration.start.time="{ item }"
                ><v-text-field
                  v-if="item.attendanceStatus == '1'"
                  v-model="item.duration.start.time"
                  type="time"
                  outlined
                  dense
                  hide-details="auto"
                  class="my-1"
                ></v-text-field
              ></template>
              <template v-slot:item.duration.end.date="{ item }"
                ><v-text-field
                  v-if="item.attendanceStatus == '1'"
                  v-model="item.duration.end.date"
                  type="date"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field
              ></template>
              <template v-slot:item.duration.end.time="{ item }"
                ><v-text-field
                  v-if="item.attendanceStatus == '1'"
                  v-model="item.duration.end.time"
                  type="time"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field
              ></template>
              <template v-slot:item.attendanceStatus="{ item }">
                <v-btn-toggle
                  v-model="item.attendanceStatus"
                  dense
                  mandatory
                  class="my-3"
                >
                  <v-btn value="1" small
                    ><v-icon left>mdi-check-circle</v-icon>Anwesend</v-btn
                  >
                  <v-btn value="2" small
                    ><v-icon left>mdi-close-circle</v-icon>Entschuldigt</v-btn
                  >
                  <v-btn value="3" small
                    ><v-icon left>mdi-close-box</v-icon>Unentschuldigt</v-btn
                  >
                </v-btn-toggle>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <v-card-text>
                    <v-row>
                      <v-col
                        v-if="item.attendanceStatus === '1'"
                        cols="12"
                        sm="6"
                        md="4"
                      >
                        <v-text-field
                          :value="getAssignedPosition(item.assignedPosition)"
                          outlined
                          disabled
                          dense
                          hide-details="auto"
                          label="Einsatzmittel & Position"
                        ></v-text-field>
                        <!-- <v-select
                      v-model="item.assignedPosition"
                        :items="possiblePositionAssignments"
                        outlined
                        dense
                        small-chips
                        hide-details="auto"
                        label="Einsatzmittel & Position"
                      ></v-select> -->
                      </v-col>
                      <v-col
                        v-if="item.attendanceStatus === '1'"
                        cols="12"
                        sm="6"
                        md="4"
                      >
                        <v-select
                          v-model="item.payrollType"
                          :items="payrollTypes"
                          outlined
                          dense
                          small-chips
                          item-text="title"
                          item-value="id"
                          return-object
                          hide-details="auto"
                          label="Abrechnungsart"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model.trim="item.note"
                          outlined
                          dense
                          hide-details="auto"
                          label="Bemerkung"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </td>
              </template>
              <template v-slot:item.action="{ item }"
                ><v-btn
                  icon
                  text
                  color="error"
                  @click="deleteAttendanceEntry(item.meta.id)"
                  ><v-icon>mdi-delete</v-icon></v-btn
                ></template
              >
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import { db, auth, FieldValue } from "@/firebase";
import { ACCOUNTING } from "@/store/modules.js";
import { GET_PAYROLL_TYPES } from "@/store/action-types.js";
export default {
  name: "report-live-attendances-view",
  components: { SupportTools },
  props: {
    attendancesObject: {},
    report: {},
  },
  data() {
    return {
      helpers: {
        newAttendanceEntry: {
          attendanceStatus: "1",
          attendee: null,
          isGuest: false,
          isExternal: false,
        },
        attendanceDeletion: {
          loading: false,
          selection: [],
        },
        attendanceSelector: {
          model: false,
          isValid: false,
        },
        attendanceTable: {
          loading: false,
        },
      },
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },

      headers: [
        { text: "Name", value: "user.displayName" },
        { text: "Startdatum", value: "duration.start.date" },
        { text: "Startzeit", value: "duration.start.time" },
        { text: "Enddatum", value: "duration.end.date" },
        { text: "Endzeit", value: "duration.end.time" },
        { text: "Status", value: "attendanceStatus" },
        { text: "", value: "data-table-expand", sortable: false },
        { value: "action", sortable: false, width: "1%" },
      ],
      expandedAttendanceRows: [],
    };
  },
  model: {
    prop: "attendancesObject",
    event: "attendancesChange",
  },
  computed: {
    attendancesLocal: {
      get: function () {
        return this.attendancesObject;

        // {
        //   ...this.attendancesObject,
        //   data: this.attendancesObject.data.map((item) => {
        //     return {
        //       ...item,
        //       groupByDivision:
        //         (item.user.division.sortKey.toString() || "0") +
        //         "_" +
        //         (item.user.division.title || ""),
        //     };
        //   }),
        // };
      },
      set: function (value) {
        console.log(value);
        this.$emit("attendancesChange", value);
      },
    },
    reportLocal: {
      get: function () {
        return this.report;
      },
      set: function (value) {
        this.$emit("reportChange", value);
      },
    },
    checkIfSupport() {
      return this.$store.getters["user/checkIfSupport"];
    },
    code() {
      return [
        { title: "reportLocal", data: this.reportLocal },
        { title: "attendancesLocal", data: this.attendancesLocal },
        { title: "addableAttendees", data: this.addableAttendees },
      ];
    },
    possiblePositionAssignments() {
      // todo: as a function/method, suggestions of possible positions should be marked as "selected
      return [];
    },
    memberships() {
      return this.$store.state.admin.membershipsPublic;
    },
    addableAttendees() {
      if (!this.reportLocal) return [];
      const range = {
        start:
          new Date(this.reportLocal.duration.start.timestampToDate) ||
          new Date(),
        end:
          new Date(this.reportLocal.duration.end.timestampToDate) || new Date(),
      };

      const membershipsFiltered = this.memberships.filter((obj) => {
        const memberSince = new Date(obj.user.memberSinceToDate);
        const memberUntil = new Date(obj.user.memberUntilToDate);

        return (
          memberSince <= range.start && // All members that were members before or at the start of this event
          !(memberUntil <= range.end) && // All members that didn't leave before or at the end of this event
          obj.access.type === "member"
        );
      });

      const attendanceIdsWithStatus2Or3 = this.attendancesLocal.data
        .filter(
          (attendance) =>
            attendance.attendanceStatus === "2" ||
            attendance.attendanceStatus === "3"
        )
        .map((attendance) => attendance.user.uid);

      const result = membershipsFiltered.map((membership) => {
        if (attendanceIdsWithStatus2Or3.includes(membership.user.uid)) {
          return { ...membership, disabled: true };
        }
        return { ...membership, disabled: false };
      });

      return result;
    },
    reportStart() {
      return {
        date: this.convertToDate(this.reportLocal.duration.start.timestamp),
        time: this.convertToTime(this.reportLocal.duration.start.timestamp),
      };
    },
    reportEnd() {
      return {
        date: this.convertToDate(this.reportLocal.duration.start.timestamp),
        time: this.convertToTime(this.reportLocal.duration.start.timestamp),
      };
    },
    payrollTypes() {
      return this.$store.state.accounting.payrollTypes.map((payrollType) => {
        return {
          title: payrollType.title,
          id: payrollType.meta.id,
        };
      });
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      // Get payrolltypes
      this.$store.dispatch(`${ACCOUNTING}${GET_PAYROLL_TYPES}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
    async loadResponsesFromEvent() {
      if (!this.reportLocal.eventId) return;

      const confirmed = confirm(
        "Bereits erfasste Anwesenheitseinträge werden mit den Rückmeldungen aus der Veranstaltung überschrieben. Möchtest Du fortfahren?"
      );
      if (!confirmed) return;

      const { organizationId, itemId } = this.$route.params;
      const { eventId } = this.reportLocal;
      const orgRef = db.collection("organizations").doc(organizationId);
      const eventResponsesRef = orgRef
        .collection("events")
        .doc(eventId)
        .collection("responses");
      const dutyAttendancesRef = orgRef
        .collection("dutyReports")
        .doc(itemId)
        .collection("dutyAttendances");

      try {
        const existingAttendanceEntryIds = this.attendancesLocal.data.map(
          (item) => item.meta.id
        );
        await this.deleteAttendanceEntries(existingAttendanceEntryIds);

        const querySnapshot = await eventResponsesRef.get();
        const addedUids = new Set();

        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            const response = doc.data();
            const newAttendanceEntry = this.createAttendanceEntry(
              response.meta.uid,
              response.status.id,
              eventId,
              dutyAttendancesRef.id
            );
            this.attendancesLocal.data.push(newAttendanceEntry);
            addedUids.add(response.meta.uid);
          });
        } else {
          console.log("No documents found in the responses collection.");
        }

        this.addableAttendees.forEach((attendee) => {
          if (!addedUids.has(attendee.user.uid)) {
            const newAttendanceEntry = this.createAttendanceEntry(
              attendee.user.uid,
              null,
              eventId,
              dutyAttendancesRef.id
            );
            this.attendancesLocal.data.push(newAttendanceEntry);
          }
        });

        console.log("Operation erfolgreich abgeschlossen!");
      } catch (error) {
        console.error("Operation fehlgeschlagen: ", error);
      }
    },
    createAttendanceEntry(uid, statusId, eventId, docId) {
      return {
        duration: {
          start: {
            date: this.reportStart.date || null,
            time: this.reportStart.time || null,
            timestamp: null,
          },
          end: {
            date: this.reportLocal.duration.end.date || null,
            time: this.reportLocal.duration.end.time || null,
            timestamp: null,
          },
          inHours: null,
          inMinutes: null,
        },
        user: this.getUserAttendanceData(uid),
        attendanceStatus: this.getUserAttendanceStatus(statusId),
        assignedPosition: {
          unit: { title: null, id: null },
          position: { title: null, id: null },
          comboId: "",
        },
        payrollType: this.reportLocal.payrollType,
        organizationId: this.$route.params.organizationId,
        dutyReportId: this.$route.params.itemId,
        meta: {
          id: docId,
          importedFromEvent: eventId,
          lastUpdatedAt: FieldValue.serverTimestamp(),
          lastUpdatedBy: auth.currentUser.uid,
        },
      };
    },
    getAssignedPosition(assignedPosition) {
      if (!assignedPosition) return "Nicht festgelegt";
      const unitTitle = assignedPosition.unit?.title || "";
      const positionTitle = assignedPosition.position?.shortTitle || "";

      if (unitTitle && positionTitle) {
        return `${unitTitle} - ${positionTitle}`;
      } else if (unitTitle) {
        return unitTitle;
      } else if (positionTitle) {
        return positionTitle;
      } else {
        return "Nicht festgelegt";
      }
    },
    getUserAttendanceStatus(responseStatusId) {
      switch (responseStatusId) {
        case "1": // verfügbar
          return "1"; // anwesend
        case "2" || "3" || "4": // nicht verfügbar
          return "2"; // entschuldigt
        default:
          return "3"; // unentschuldigt
      }
    },
    getUserAttendanceData(uid) {
      const user = this.memberships.find((item) => item.user.uid === uid);
      return {
        displayName: user.user.displayName,
        uid: user.user.uid,
        division: {
          title: user.user.division.title,
          sortKey: user.user.division.sortKey,
          id: user.user.division.id,
        },
        rank: {
          shortTitle: user.user.rank.shortTitle,
          id: user.user.rank.id,
          title: user.user.rank.title,
          sortKey: user.user.rank.sortKey,
        },
        dutyPosition: {
          shortTitle: user.user.rank.shortTitle,
          sortKey: user.user.rank.sortKey,
          id: user.user.rank.id,
          title: user.user.rank.title,
        },
        group: user.group,
        fillablePositions: user.fillablePositions,
        relevantSkills: user.relevantSkills,
      };
    },
    addAttendanceEntry() {
      const dutyAttendancesRef = db
        .collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("dutyReports")
        .doc(this.$route.params.itemId)
        .collection("dutyAttendances")
        .doc();

      const userData = {
        division: {
          title: this.helpers.newAttendanceEntry.user.user.division.title,
          shortTitle:
            this.helpers.newAttendanceEntry.user.user.division.shortTitle,
          sortKey: this.helpers.newAttendanceEntry.user.user.division.sortKey,
          id: this.helpers.newAttendanceEntry.user.user.division.id,
        },
        rank: {
          shortTitle: this.helpers.newAttendanceEntry.user.user.rank.shortTitle,
          id: this.helpers.newAttendanceEntry.user.user.rank.id,
          title: this.helpers.newAttendanceEntry.user.user.rank.title,
          sortKey: this.helpers.newAttendanceEntry.user.user.rank.sortKey,
        },
        dutyPosition: {
          shortTitle: this.helpers.newAttendanceEntry.user.user.rank.shortTitle,
          sortKey: this.helpers.newAttendanceEntry.user.user.rank.sortKey,
          id: this.helpers.newAttendanceEntry.user.user.rank.id,
          title: this.helpers.newAttendanceEntry.user.user.rank.title,
        },
        group: this.helpers.newAttendanceEntry.user.group,
        fillablePositions:
          this.helpers.newAttendanceEntry.user.fillablePositions,
        relevantSkills: this.helpers.newAttendanceEntry.user.relevantSkills,
        uid: this.helpers.newAttendanceEntry.user.user.uid,
        displayName: this.helpers.newAttendanceEntry.user.user.displayName,
      };

      this.attendancesLocal.data.push({
        duration: {
          start: {
            date: this.reportStart.date ? this.reportStart.date : null,
            time: this.reportStart.time ? this.reportStart.time : null,
            timestamp: null,
          },
          end: {
            date: this.reportLocal.duration.end.date || null,
            time: this.reportLocal.duration.end.time || null,
            timestamp: null,
          },
        },
        user: userData,
        attendanceStatus: this.helpers.newAttendanceEntry.attendanceStatus,
        assignedPosition: {
          unit: { title: null, id: null },
          position: { title: null, id: null },
          comboId: "",
        },
        payrollType: this.reportLocal.payrollType || { title: null, id: null },
        organizationId: this.$route.params.organizationId,
        dutyReportId: this.$route.params.itemId,
        meta: {
          id: dutyAttendancesRef.id,
          lastUpdatedAt: FieldValue.serverTimestamp(),
          lastUpdatedBy: auth.currentUser.uid,
        },
      });
      this.helpers.attendanceSelector.model = false;
      this.helpers.newAttendanceEntry = {
        attendanceStatus: "1",
        attendee: null,
        isGuest: false,
        isExternal: false,
      };
    },
    massEditAttendanceSelection() {},
    deleteAttendanceEntry(id) {
      this.deleteAttendanceEntries([id]);
    },
    deleteAttendanceSelection() {
      this.helpers.attendanceDeletion.loading = true;
      const attendanceIds = this.helpers.attendanceDeletion.selection.map(
        (item) => item.meta.id
      );
      this.deleteAttendanceEntries(attendanceIds).then(() => {
        this.helpers.attendanceDeletion.selection = [];
        this.helpers.attendanceDeletion.loading = false;
      });
    },
    deleteAttendanceEntries(attendanceIds) {
      this.helpers.attendanceTable.loading = true;
      const batch = db.batch();
      attendanceIds.forEach((item) => {
        const dutyReportRef = db
          .collection("organizations")
          .doc(this.$route.params.organizationId)
          .collection("dutyReports")
          .doc(this.$route.params.itemId);
        const dutyAttendancesRef = dutyReportRef.collection("dutyAttendances");
        const attendanceRef = dutyAttendancesRef.doc(item);
        batch.delete(attendanceRef);
      });

      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

      return Promise.all([
        batch.commit().catch((error) => {
          console.error("Error removing document: ", error);
        }),
        delay(750),
      ]).then(() => {
        this.helpers.attendanceTable.loading = false;
      });
    },
    convertToDate(date) {
      date = date.toDate();
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1 < 10 ? "0" : "") +
        (date.getMonth() + 1) +
        "-" +
        (date.getDate() < 10 ? "0" : "") +
        date.getDate()
      );
    },
    convertToTime(time) {
      time = time.toDate();
      return (
        (time.getHours() < 10 ? "0" : "") +
        time.getHours() +
        ":" +
        (time.getMinutes() < 10 ? "0" : "") +
        time.getMinutes()
      );
    },
  },
};
</script>
<style scoped lang="scss">
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>
